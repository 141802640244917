{
  "celerlogica": {
    "name": "CelerLogica",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://celerlogica.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/celerlogica"
      }
    ]
  },
  "omari-jazz": {
    "name": "Omari Jazz",
    "bio": "",
    "links": [
      {
        "name": "Website",
        "url": "https://omarijazz.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/omarijazz"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/omarijazz"
      }
    ]
  },
  "chucknessa": {
    "name": "CHUCKNESSA",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://chucknessa.bandcamp.com"
      },
      {
        "name": "Spotify",
        "url": "https://open.spotify.com/artist/3xQvm4TtciKKGQ14nn6ph0?si=rjby9wKhRnau-kNs6JoRTw"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/chucknessa"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/chucknessa"
      }
    ]
  },
  "swarvy": {
    "name": "Swarvy",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://swarvy.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/swarvy"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/swarvy"
      }
    ]
  },
  "olarian": {
    "name": "Olarian",
    "bio": "",
    "links": [
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/olarian"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/olarian"
      }
    ]
  },
  "kai": {
    "name": ".kai",
    "bio": "",
    "links": [
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/kingofpalmtrees"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/kingofpalmtrees"
      }
    ]
  },
  "buli": {
    "name": "Buli",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://bulifromspace.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/bulifromspace"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/bulifromspace"
      }
    ]
  },
  "104-rog": {
    "name": "10.4 ROG",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://104rog.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/104rog"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/104rog"
      }
    ]
  },
  "silentjay": {
    "name": "SILENTJAY",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://napkingcole.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/silentjay"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/nap.king.cole"
      }
    ]
  },
  "ihaveneighbors": {
    "name": "ihaveneighbors",
    "bio": "",
    "links": [
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/ihaveneighbors"
      }
    ]
  },
  "scape": {
    "name": "Scape",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://scapesounds.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/scapesounds"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/scape.sounds"
      }
    ]
  },
  "woot-noot": {
    "name": "Woot Noot",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://wootnoot.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/wootnoot"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/wootnooot"
      }
    ]
  },
  "a-luxury": {
    "name": "A-LUXURY",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://a-luxury.bandcamp.com"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/antwaaan"
      }
    ]
  },
  "devonwho": {
    "name": "Devonwho",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://devonwho.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/devonwho"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/devonwho"
      }
    ]
  },
  "fizzy": {
    "name": "fizzy",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://fizzysan.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/fizzysan"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/fza.png/"
      }
    ]
  },
  "jon-vuri": {
    "name": "jon vuri",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://jonvuri.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/jonvuri"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/jonvuri/"
      }
    ]
  },
  "kifferei": {
    "name": "kifferei",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://kifferei.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/kifferei"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/kifferei/"
      }
    ]
  },
  "letjoux": {
    "name": "letjoux",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://letjoux.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/letjoux"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/letjoux/"
      }
    ]
  },
  "myself": {
    "name": "myself",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://mmmyself.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/m_y_s_e_l_f"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/mmmmmmmmmmyself/"
      }
    ]
  },
  "broken-transient": {
    "name": "Broken Transient",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://brokentransient.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/brokentransient"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/brokentransient/"
      }
    ]
  },
  "finem": {
    "name": "Finem",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://finem.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/finem"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/fynnmichlin/"
      }
    ]
  },
  "ricky": {
    "name": "Ricky",
    "bio": "",
    "links": [
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/user-33163533"
      }
    ]
  },
  "jay-vinyl": {
    "name": "JAY VINYL",
    "bio": "",
    "links": [
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/jayvinyl"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/emptyphonebooth/"
      }
    ]
  },
  "alexander-king": {
    "name": "Alexander King",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://kingnik.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/alex-king-514821008"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/gnikxela"
      }
    ]
  },
  "toby-schay": {
    "name": "Toby Schay",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://tobyschay.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/tobyschay"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/tobyschay"
      }
    ]
  },
  "subnet": {
    "name": "SUBNET",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://nambokurecords.bandcamp.com/album/out-the-tape"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/subnet-beats"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/blackboxlying"
      }
    ]
  },
  "toonorth": {
    "name": "Toonorth",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://toonorth.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/countbazzy-2"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/toonorth_"
      }
    ]
  },
  "vooo": {
    "name": "Vooo",
    "bio": "",
    "links": [
      {
        "name": "Website",
        "url": "https://vooomusic.com"
      },
      {
        "name": "Bandcamp",
        "url": "https://vooo.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/vooomusic"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/vooo.music"
      }
    ]
  },
  "ashtrejinkins": {
    "name": "AshTreJinkins",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://ashtrejinkins.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/thinkinjinkins"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/_ashtrejinkins"
      }
    ]
  },
  "antoje": {
    "name": "aNTOJE",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://antoje.bandcamp.com"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/tttuuujjj911117"
      }
    ]
  },
  "shri": {
    "name": "Shri!",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://shri.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/thatguyshri"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/thisguyshri"
      }
    ]
  },
  "ideism": {
    "name": "ideism",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://ideism.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/shri"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/ideism_"
      }
    ]
  },
  "sjs": {
    "name": "sjs",
    "bio": "",
    "links": [
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/user-951731753"
      }
    ]
  },
  "reid": {
    "name": "reid",
    "bio": "",
    "links": [
      {
        "name": "Website",
        "url": "https://reid.cat"
      },
      {
        "name": "Bandcamp",
        "url": "https://reid.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/cosy"
      }
    ]
  },
  "stillakid": {
    "name": "StillAKid",
    "bio": "",
    "links": [
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/whileimstillakid"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/stillakid__"
      }
    ]
  },
  "sobolik": {
    "name": "sobolik",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://allcentre.bandcamp.com/album/iris"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/tomsobolikmusic"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/e_sobolik"
      }
    ]
  },
  "cymk": {
    "name": "cy.mk",
    "bio": "",
    "links": [
      {
        "name": "Bandcamp",
        "url": "https://cymk.bandcamp.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/imcymk"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/_cymk"
      }
    ]
  },
  "kwame": {
    "name": "Kwamé",
    "bio": "",
    "links": [
      {
        "name": "Spotify",
        "url": "https://open.spotify.com/artist/1KIS0P3Qlw8evjLOQvxcAi?si=Ou5srKwFT8-S8sqz7vW2jw&nd=1"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/kwame12345"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/kwameskingdom_"
      }
    ]
  },
  "thoto_leing": {
    "name": "Thoto_Leing",
    "bio": "",
    "links": [
      {
        "name": "Spotify",
        "url": "https://open.spotify.com/artist/5a4nrJ6eEVHg5CDeTbPwlm?si=CYo2_78kT_-Qvh4rxEAq2g"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/th0to"
      },
      {
        "name": "BandCamp",
        "url": "https://thirstycityrecords.bandcamp.com/album/thoto-leing"
      }
    ]
  },
  "bry-zen": {
    "name": "Bry.Zen",
    "bio": "",
    "links": [
      {
        "name": "Spotify",
        "url": "https://open.spotify.com/artist/7M1ZrDbXTmd8soHh0ziIoR?si=oJGqte8XRPCoIH2kq9oaaQ"
      },
      {
        "name": "Website",
        "url": "https://www.bryzen.net/"
      },
      {
        "name": "Instagram",
        "url": "https://www.instagram.com/bry.z3n"
      }
    ]
  },
  "theyalright": {
    "name": "Theyalright",
    "bio": "",
    "links": [
      {
        "name": "Website",
        "url": "https://theyalright.com"
      },
      {
        "name": "SoundCloud",
        "url": "https://soundcloud.com/theyalright"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/theyalright/"
      }
    ]
  }
}
