import React from 'react';

export default function Homes() {
  return (
    <div className='profile'>
      <h1>
        <span className='blue'>My homies</span> make good music.
      </h1>
    </div>
  )
}
